<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicles">Fahrzeuge</router-link></li>
              <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">{{ vehicle.license_plate }}</router-link></li>
              <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/documents'">Dokumente</router-link></li>
              <li>Neu</li>
            </ul>
            <div class="header-actions">
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">Stammdaten</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/reservations'">Reservierungen</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/damages'">Schäden</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/repairs'">Reparaturen</router-link></li>
            <!--<li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/insurance'">Versicherung</router-link></li>-->
            <li class="active"><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/documents'">Dokumente</router-link></li>
          </ul>

          <div class="admin-body">

            <form @submit="create_document" class="form" method="post">

              <div class="form-wrap">
                <label for="damage_number">Name</label>
                <input v-model="vehicle_document.name" class="form-input" type="text" name="damage_number" id="damage_number">
              </div>

              <div class="form-wrap">
                <label>Dokument (.pdf)</label>
                <input type="file" @change="set_document">
              </div>

              <p>
                <input type="submit" value="Speichern ›" class="button button-orange button-small">
              </p>

            </form>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'

export default {
  name: 'new_admin_vehicle_repair',
  components: {
    AdminNav
  },
  data () {
    return {
      vehicle: {},
      vehicle_document: {},
      temp_document: null
    }
  },
  methods: {
    get_data() {
      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.vehicle = response.data.vehicle;
      })
    },
    create_damage: function (e) {
      e.preventDefault();

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id+'/documents', this.vehicle_document, { headers: { Authorization: this.$store.getters.getToken }})
      .then(() => {
        this.$router.push('/admin/vehicles/'+this.$route.params.id+'/documents');
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: val,
          });
        })
      })

    },
    create_document: function (e) {
      e.preventDefault();

      var form_data = new FormData();
      form_data.append("name", this.vehicle_document.name);
      form_data.append("document", this.temp_document);

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id+'/documents', form_data, { headers: { Authorization: this.$store.getters.getToken }})
      .then(() => {
        this.$router.push('/admin/vehicles/'+this.$route.params.id+'/documents');
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      })
    },
    set_document(event) {
      this.temp_document = event.target.files[0];
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
